/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState } from 'react';
import cx from 'classnames';
import { FormControlLabel } from '../../../FormControlLabel/FormControlLabel';
import { globalConstant } from '../../../../constants/global';
import Checkbox from '../../../Checkbox/Checkbox';
import CheckBoxSharpIcon from '../../../../../public/images/checkbox-sharp.svg';
import CheckBoxOutlineBlankSharpIcon from '../../../../../public/images/checkbox-outline-blank-sharp.svg';
import iconStyles from '../../../../theme/iconStyles.module.scss';
import { useIsFocusVisible } from '@/hooks/useIsFocusVisible';
import { useEventCallback } from '@/hooks/useEventCallback';
import style from './styles.module.scss';
type InputProps = {
  value?: boolean;
  onChange?: Function;
};
type Props = {
  classes?: any;
  status?: 'disabled' | '';
  checked?: boolean;
  label?: string | React.ReactNode;
  ariaLabel?: string;
  name?: string;
  id?: string;
  input?: InputProps;
  inputProps?: any;
  onChange?: Function;
  value?: any;
  variant?: string;
  inputRef?: Function | any;
  'data-testid'?: string;
  rootClassName?: string;
};

function CheckBox(props: Props) {
  const {
    id,
    input,
    checked,
    label,
    ariaLabel,
    value,
    onChange,
    name,
    variant,
    inputRef,
    inputProps,
    rootClassName,
    ...restInputProps
  } = props;
  const { isFocusVisible, onBlurVisible } = useIsFocusVisible();
  const [focusVisible, setFocusVisible] = useState(false);
  const onInputFocus = useEventCallback((e: MouseEvent | TouchEvent) => {
    if (isFocusVisible(e)) {
      setFocusVisible(true);
    }
  });
  const onInputBlur = () => {
    onBlurVisible();
    setFocusVisible(false);
  };

  let isChecked = checked;

  if (input) {
    const checkValue: boolean | string = input.value ?? false;
    // @ts-expect-error fix type
    isChecked = checkValue || checkValue === 'true' ? true : false;
  }

  let iconStyle: string | undefined = style.orangeColored;
  if (props.status === 'disabled') {
    iconStyle = style.disabled;
  } else if (name === globalConstant.saveThisAddress) {
    iconStyle = style.orangeColored;
  } else if (!isChecked) {
    iconStyle = undefined;
  }

  const unCheckedIcon =
    variant === 'natural' ? (
      <span className={cx(style.uncheckedIcon, iconStyle)} />
    ) : (
      <CheckBoxOutlineBlankSharpIcon className={cx(iconStyles.defaultIconStyle, iconStyle)} />
    );
  const dataTestId = restInputProps['data-testid'] || 'check-box';
  return (
    <FormControlLabel
      className={cx(rootClassName, style.checkBoxContainer, variant ? style[variant] : null, {
        [style.focusVisible]: focusVisible,
      })}
      control={
        <Checkbox
          data-testid={isChecked ? `${dataTestId}-checked` : `${dataTestId}-unchecked`}
          inputRef={inputRef}
          inputProps={inputProps}
          checked={isChecked}
          id={id}
          disabled={props.status === 'disabled'}
          className={cx(style.rootCheckBox, style.checked, style.disable)}
          icon={unCheckedIcon}
          checkedIcon={<CheckBoxSharpIcon className={cx(iconStyles.defaultIconStyle, iconStyle)} />}
          // @ts-expect-error fix type
          onChange={input ? input.onChange : onChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          value={value}
          name={name}
        />
      }
      label={label}
      ariaLabel={ariaLabel}
      htmlFor={id}
    />
  );
}

export default CheckBox;
