/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';

function IndeterminateCheckBoxIcon() {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg data-testid="IndeterminateCheckBoxIcon">
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z" />
    </svg>
  );
}

export default IndeterminateCheckBoxIcon;
