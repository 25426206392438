/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
 * Ported from @material-ui/core@4.11.3
 */

import * as React from 'react';
import cx from 'classnames';
import { SwitchBase } from './SwitchBase';
import CheckBoxOutlineBlankIcon from './CheckBoxOutlineBlankIcon';
import CheckBoxIcon from './CheckBoxIcon';
import IndeterminateCheckBoxIcon from './IndeterminateCheckBoxIcon';
import styles from './styles.module.scss';

type Props = React.InputHTMLAttributes<HTMLElement> & {
  /**
   * The icon to display when the component is checked.
   */
  checkedIcon?: React.ReactElement;
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  className?: any;
  /**
   * The icon to display when the component is unchecked.
   */
  icon?: React.ReactElement;
  /**
   * If `true`, the component appears indeterminate.
   * This does not set the native input element to indeterminate due
   * to inconsistent behavior across browsers.
   * However, we set a `data-indeterminate` attribute on the input.
   */
  indeterminate?: boolean;
  /**
   * The icon to display when the component is indeterminate.
   */
  indeterminateIcon?: React.ReactElement<any>;
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps?: any;
  /**
   * Pass a ref to the `input` element.
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * The size of the checkbox.
   * `small` is equivalent to the dense checkbox styling.
   */
  size?: 'medium' | 'small';
};

function Checkbox({
  checkedIcon = <CheckBoxIcon />,
  icon: iconProp = <CheckBoxOutlineBlankIcon />,
  indeterminate = false,
  indeterminateIcon: indeterminateIconProp = <IndeterminateCheckBoxIcon />,
  inputProps,
  // @ts-expect-error investigate why TS sees never
  size = 'medium',
  ...other
}: Props) {
  const icon = indeterminate ? indeterminateIconProp : iconProp;
  const indeterminateIcon = indeterminate ? indeterminateIconProp : checkedIcon;

  return (
    <SwitchBase
      type="checkbox"
      className={cx(
        styles.root,
        styles.colorPrimary,
        styles.checked,
        styles.disabled,
        indeterminate ? styles.indeterminate : null
      )}
      inputProps={{
        'data-indeterminate': indeterminate,
        ...inputProps,
      }}
      icon={React.cloneElement(icon, {
        fontSize:
          icon.props.fontSize === undefined && size !== 'medium' ? size : icon.props.fontSize,
      })}
      checkedIcon={React.cloneElement(indeterminateIcon, {
        fontSize:
          indeterminateIcon.props.fontSize === undefined && size !== 'medium'
            ? size
            : indeterminateIcon.props.fontSize,
      })}
      {...other}
    />
  );
}

export default Checkbox;
